export const BOT_TYPES = ["CEX", "DEX", "DEX_V2", "DEX_NE"] as const;

export enum RouteEnum {
	INDEX = "",
	PARTY = "party",
	BOT = "bot",
	// STORIES = "stories",
}

export enum MainRoutes {
	Root = "",
	Any = "/*",
	Error = "error-page",
	AllBots = "all-bots",
	MarketCheckPage = "market-check",
	AccessPage = "access",
	TeamsPage = "teams",
	CEXCreatingPage = "cex-bot-creating",
	DEXCreatingPage = "dex-evm-bot-creating",
	DEXNECreatingPage = "dex-ne-bot-creating",
	ExchangeAccountingPage = "exchange-accounting",
	Login = "login",
	Stories = "stories",
}

export enum StoryRoutes {
	PNLV2Story = "pnlv2",
	ToastsStory = "toasts",
	GraphStory = "graph",
	DatePickerStory = "date-picker",
	TableStory = "table",
	Form = "form",
	Modal = "modal",
	WidgetWrapper = "widget-wrapper",
	DIExample = "di-example",
}

export enum PartyRoutes {
	Bots = "bots",
	Dashboard = "dashboard",
	Strategies = "strategies",
	Modules = "modules",
	Multigrid = "multigrid",
	Funding = "funding",
	CandlesAnalytic = "candle-analytics",
	MarketCheck = "market-check",
	ManualArbitrage = "arbitrage",
	CEXLauncher = "cex-launcher",
	CEXCEXArbitrage = "cex-cex-arbitrage",
	Accounting = "accounting",
}

export enum CEXRoutes {
	Stats = "stats",
	APIKeys = "api-keys",
	Settings = "settings",
	Exchange = "exchange",
	Grid = "grid",
	Multigrid = "multigrid",
}

export enum DEXRoutes {
	Stats = "stats",
	Settings = "settings",
	Funding = "funding",
}

export enum DEXV2Routes {
	Stats = "stats",
	Settings = "settings",
	Swap = "swap",
}

export enum DEXNERoutes {
	Stats = "stats",
	Settings = "settings",
	Swap = "swap",
}
