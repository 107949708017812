import { makeAutoObservable } from "mobx";

import { ColorTheme } from "@/shared/constants/common";
import { LocalStorageKeys } from "@/shared/constants/local-storage-keys";
import { MOBILE_DEVICE_WIDTH } from "@/shared/constants/media-query";
import { checkDevice } from "@/shared/utils/check-device";
import {
	deleteFromLocalStorage,
	getFromLocalStorage,
	setToLocalStorage,
} from "@/shared/utils/local-storage";

export class GeneralStore {
	private _googleAccessToken?: string | null = null;

	private _theme = ColorTheme.LIGHT;

	private _isMobile = false;

	constructor() {
		makeAutoObservable(this);
	}

	get isMobile() {
		return this._isMobile;
	}

	get isLightTheme() {
		return this._theme === ColorTheme.LIGHT;
	}

	get theme() {
		return this._theme;
	}

	get googleAccessToken() {
		return this._googleAccessToken;
	}

	get isLoggedIn() {
		return !!this._googleAccessToken;
	}

	checkTheme = () => {
		const theme = getFromLocalStorage(LocalStorageKeys.Theme) || ColorTheme.LIGHT;

		this._theme = theme as ColorTheme;

		setToLocalStorage(LocalStorageKeys.Theme, ColorTheme.LIGHT);
	};

	setIsLightTheme = (status: boolean) => {
		this._theme = status ? ColorTheme.LIGHT : ColorTheme.DARK;

		setToLocalStorage(LocalStorageKeys.Theme, this._theme);
	};

	setIsMobile = (status?: boolean) => {
		this._isMobile = status || checkDevice(MOBILE_DEVICE_WIDTH);
	};

	setGoogleAccessToken = (token: string | null) => {
		if (token) {
			setToLocalStorage(LocalStorageKeys.GoogleAccessToken, token);
		} else {
			deleteFromLocalStorage(LocalStorageKeys.GoogleAccessToken);
		}

		this._googleAccessToken = token;
	};

	checkGoogleAccessToken = () => {
		const token = getFromLocalStorage(LocalStorageKeys.GoogleAccessToken);

		if (token) {
			this._googleAccessToken = token;
		}
	};

	clearGoogleAccessToken = () => {
		this._googleAccessToken = "";
	};

	init = () => {
		this.checkTheme();
		this.setIsMobile();
		this.checkGoogleAccessToken();
	};
}
