import { entries } from "@/shared/utils/object";

export interface PathUrlParams {
	baseUrl: string;
	prefix?: string;
	url: string;
}

const SLASHES_TRIM_REGEXP = /^\/+|\/+$/g;

/**
 * removes any leading and trailing slashes in string
 * @param str - string to trim
 * @returns string without start/end slashes
 */
export const trimStartEndSlashes = (str: string) => str.replace(SLASHES_TRIM_REGEXP, "");

/**
 * method for generating the full path of the request
 * @param baseUrl - host path
 * @param prefix - api version indication
 * @param url - final path to the resource
 * @returns
 */
export const getRequestUrl = ({ baseUrl, prefix, url }: PathUrlParams) => {
	if (!prefix) return `${baseUrl}/${trimStartEndSlashes(url)}`;

	return `${baseUrl}/${trimStartEndSlashes(prefix)}/${trimStartEndSlashes(url)}`;
};

export const joinQueryValues = (values: string[]) => values.join(",");

export type QueryParams<Params extends string> = { [K in Params]?: string | number | boolean };

export const buildQueryParamsString = <Params extends string>(params: QueryParams<Params>) => {
	const nonEmptyParams = entries(params).filter(([, value]) => Boolean(value)) as Array<
		[string, string]
	>;
	const searchParams = new URLSearchParams(nonEmptyParams);
	return `${searchParams}`;
};

export const buildQueryUrl = <Params extends string>(
	baseUrl: string,
	params?: QueryParams<Params>
) => {
	if (!params) return baseUrl;
	const paramsString = buildQueryParamsString(params);

	if (!paramsString) return baseUrl;

	return `${baseUrl}?${paramsString}`;
};
