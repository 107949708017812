import dayjs from "dayjs";
import { ethers } from "ethers";
import { AnyObject, Flags, Maybe, Message, NumberSchema, Schema, StringSchema } from "yup";

import { ERRORS_MSGS } from "../forms";

export const requiredNumber = <
	TType extends Maybe<number> = number | undefined,
	TContext = AnyObject,
	TDefault = undefined,
	TFlags extends Flags = "",
>(
	schema: NumberSchema<TType, TContext, TDefault, TFlags>,
	message?: Message
) => schema.transform((value) => (isNaN(value) ? undefined : value)).defaultRequired(message);

export const defaultRequired = <
	TType = any,
	TContext = any,
	TDefault = any,
	TFlags extends Flags = "",
>(
	schema: Schema<TType, TContext, TDefault, TFlags>,
	message: Message = ERRORS_MSGS.isRequired
) => schema.required(message);

export const minUnixDateValidation = <
	TType extends Maybe<number>,
	TContext,
	TDefault,
	TFlags extends Flags,
>(
	schema: NumberSchema<TType, TContext, TDefault, TFlags>,
	date: string
) => schema.min(dayjs.utc(date).unix(), `Date must be >= ${date}`);

export const isEthersAddress = <
	TType extends Maybe<string> = string | undefined,
	TContext = AnyObject,
	TDefault = undefined,
	TFlags extends Flags = "",
>(
	schema: StringSchema<TType, TContext, TDefault, TFlags>,
	message?: Message
) =>
	schema.test("is-valid-ethereum-address", message || "Incorrect public address", (value) =>
		ethers.utils.isAddress(value || "")
	);

export const smallerThan = <
	TType extends Maybe<number> = number | undefined,
	TContext = AnyObject,
	TDefault = undefined,
	TFlags extends Flags = "",
>(
	schema: NumberSchema<TType, TContext, TDefault, TFlags>,
	keys: string | string[],
	messageParam?: [string, string],
	message?: Message
) =>
	schema.when(keys, ([v], schema) =>
		schema.max(
			v,
			messageParam ? `${messageParam[0]} must be smaller than ${messageParam[1]}` : message
		)
	);

export const greaterThan = <
	TType extends Maybe<number> = number | undefined,
	TContext = AnyObject,
	TDefault = undefined,
	TFlags extends Flags = "",
>(
	schema: NumberSchema<TType, TContext, TDefault, TFlags>,
	keys: string | string[],
	messageParam?: [string, string],
	message?: Message
) =>
	schema.when(keys, ([v], schema) =>
		schema.min(
			v,
			messageParam ? `${messageParam[0]} must be greater than ${messageParam[1]}` : message
		)
	);
