import { responseHandler } from "@/shared/api/fetch/response-handler";
import { ApiModules } from "@/shared/api/types";
import { refreshTokenRequestConfig, updateRefreshTokenStorage } from "@/shared/api/utils/auth";
import { getApiUrl } from "@/shared/api/utils/get-api-url";
import { getRefreshToken } from "@/shared/api/utils/get-token";
import { logError } from "@/shared/utils/logger";

import { CheckCode, Credentials } from "./types";

export const logIn = async (credentials: Credentials) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Empty, "/login"),
		method: "POST",
		data: credentials,
		auth: false,
	});

export const sendNewCode = async (login: string, token: string) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Empty, "/send-new-email"),
		method: "POST",
		data: { login },
		auth: {
			token,
		},
	});

export const checkCode = async (data: CheckCode, token: string) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Empty, "check-code"),
		method: "POST",
		data,
		auth: {
			token,
		},
	});

export const reqRefreshToken = async (token: string | null) =>
	responseHandler.handler(refreshTokenRequestConfig(token));

/** method for refresh tokens and user abilities in local storage */
export const refreshToken = async (successCb?: () => void) => {
	const token = getRefreshToken();

	if (!token) return;

	try {
		const { isError, data } = await reqRefreshToken(token);

		if (!isError) {
			successCb?.();
			updateRefreshTokenStorage(data);
		}
	} catch (err) {
		logError(err);
	}
};
