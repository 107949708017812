import { entries } from "@/shared/utils/object";

import { Nullish } from "../types/common-types";

import { filterBoolean } from "./array";
import { isDefined } from "./common";
import { roundToTwoDecimals } from "./rounding";
import { numFormatter } from "./separation";

export const toLowercaseFirstLetter = (str: string) => str.charAt(0).toLowerCase() + str.slice(1);

export const toUppercaseFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const capitalizeOnlyFirstLetter = (str: string) =>
	str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();

export const filterCallback = (value: string, filter: string) =>
	value.toLowerCase().includes(filter.toLowerCase());

export const strShortener = (
	text: string,
	start: number | undefined = 5,
	end: number | undefined = 4,
	separator: string | undefined = "..."
) =>
	text.length > 9
		? text.slice(0, start) + separator + text.slice(text.length - end, text.length)
		: text;

export const joinStrings = (arr: Array<Nullish<string>>, separator = "\n") => {
	const filteredArray = filterBoolean(arr);

	return filteredArray.join(separator);
};

export const objectToFormatString = (obj: Record<string, any>) => {
	const formattedEntries = entries(obj).map(
		([key, value]) => `${key}: ${isDefined(value) ? value : "-"}`
	);

	return joinStrings(formattedEntries);
};

export const formatWithSign = (rawValue: string | number, value: string) => {
	if (Math.sign(+rawValue) > 0) {
		return `+${value}`;
	}
	return `${value}`;
};

export const formatFiat = (
	value: Nullish<number | string>,
	showSymbol = true,
	showSign = false
) => {
	const roundedValue = isDefined(value) ? roundToTwoDecimals(+value) : "-";

	const formattedValue = numFormatter(roundedValue);

	const valueWithSign = showSign ? formatWithSign(roundedValue, formattedValue) : formattedValue;

	return showSymbol ? `${valueWithSign} $` : valueWithSign;
};

export const formatPercent = (
	value: Nullish<number | string>,
	showSymbol = true,
	showSign = false
) => {
	const roundedValue = isDefined(value) ? roundToTwoDecimals(+value) : "-";

	const valueWithSign = showSign ? formatWithSign(roundedValue, `${roundedValue}`) : roundedValue;

	return showSymbol ? `${valueWithSign}%` : `${valueWithSign}`;
};

export const formatDecimalPercent = (
	value: Nullish<number | string>,
	showSymbol = true,
	showSign = false
) => {
	const percentage = value && +value * 100;

	return formatPercent(percentage, showSymbol, showSign);
};

export const formatPNLWithSign = (value: number, type: "fiat" | "percent", showSymbol = true) => {
	const formattedValue =
		type === "fiat" ? formatFiat(value, showSymbol, true) : formatPercent(value, showSymbol, true);
	return formattedValue;
};

/**
 * Split snake_case string and convert it to title case.
 * Example: "critical_balance" -> "Critical Balance"
 */
export const convertSnakeToTitleCase = (str: string) =>
	str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
