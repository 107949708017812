import { ComponentPropsWithoutRef } from "react";
import { observer } from "mobx-react-lite";

import LoaderDarkIcon from "@/shared/assets/images/loader.gif";
import LoaderLightIcon from "@/shared/assets/images/loaderLight.gif";
import { useGeneralState } from "@/shared/stores/app-state";

interface SpinnerIndicatorProps extends ComponentPropsWithoutRef<"img"> {}

export const SpinnerIndicator = observer((props: SpinnerIndicatorProps) => {
	const { isLightTheme } = useGeneralState();

	return <img src={isLightTheme ? LoaderDarkIcon : LoaderLightIcon} alt="Loading..." {...props} />;
});
