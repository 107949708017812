import { makeAutoObservable } from "mobx";

import { LocalStorageKeys } from "@/shared/constants/local-storage-keys";
import { Abilities, AbilityType } from "@/shared/types/abilities-types";
import { checkAbility, checkParty } from "@/shared/utils/check-abilities";
import { getFromLocalStorage } from "@/shared/utils/local-storage/get-from-local-storage";

export class AbilitiesStore {
	private _party: string | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	get abilities(): Abilities {
		const jsonAbilities = getFromLocalStorage(LocalStorageKeys.Abilities);

		if (jsonAbilities) {
			return JSON.parse(jsonAbilities) as Abilities;
		}

		return {};
	}

	get party() {
		return this._party;
	}

	setParty = (party: string | null) => {
		this._party = party;
	};

	resetParty = () => {
		this._party = null;
	};

	checkMainLayerAbility = (ability: AbilityType) => checkAbility(this.abilities, ability);

	checkPartyLayerAbility = (party: string, ability: AbilityType) =>
		checkParty(this.abilities, ability, party);

	hasAbility = (ability: AbilityType) => {
		const isPartyLayer = !!this._party;

		if (!isPartyLayer) return checkAbility(this.abilities, ability);

		if (!this._party) {
			return false;
		}

		return checkParty(this.abilities, ability, this._party);
	};
}
