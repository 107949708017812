import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";

import LogoIcon from "@/shared/assets/images/logo.png";
import { MainRoutes } from "@/shared/routing";

import s from "./logo.module.scss";

export const Logo = observer(() => (
	<NavLink to={MainRoutes.Root} className={s.logoButton}>
		<img src={LogoIcon} alt="Logo" className={s.logo} />
	</NavLink>
));
