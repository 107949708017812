import { jwtDecode } from "jwt-decode";
import { createAtom, makeAutoObservable } from "mobx";

import { getRefreshToken, setAccessToken, setRefreshToken } from "@/shared/api/utils/get-token";
import { getCurrentUnix } from "@/shared/utils/date-utils";

const TOKEN_RECEIVING_INTERVAL = 60000;

/*
EXPIRED TOKENS. Use these tokens to test the token expiration logic.

token:
eyJhbGciOiJFZERTQSIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3Mjc0Mzk4NDMsImlhdCI6MTcyNzQzODA0MywiaWQiOjAsIm5hbWUiOiJ2bGFkaXNsYXZfbGVzaGtvdiIsImVtYWlsIjoidmxhZGlzbGF2Lmxlc2hrb3ZAZ290Yml0LmlvIiwiaXNfYWN0aXZlIjp0cnVlLCJ0Z19oYW5kbGVyIjoiIiwiZ3JvdXBfdHlwZSI6IiIsImdyb3VwX25hbWUiOiIiLCJ1c2VyX2dyb3VwcyI6e319.jJ7QThLGsqLW4a0An7ATqQUM7bi_7iJBdEgdlE2qOD_DLv1IlEly_bucmpTIZWKKUNeIkpO1Rq0Ncif3UxFFBg

refresh token:
eyJhbGciOiJFZERTQSIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3Mjc0NzA0NDMsImlhdCI6MTcyNzQzODA0MywiaWQiOjAsIm5hbWUiOiJ2bGFkaXNsYXZfbGVzaGtvdiIsImVtYWlsIjoidmxhZGlzbGF2Lmxlc2hrb3ZAZ290Yml0LmlvIiwiaXNfYWN0aXZlIjp0cnVlLCJ0Z19oYW5kbGVyIjoiIiwiZ3JvdXBfdHlwZSI6IiIsImdyb3VwX25hbWUiOiIiLCJ1c2VyX2dyb3VwcyI6e319.FMIVzdMRDISioDWlO0Wi_CiMuKd-i8MHj5E-YC-CMQ6NGNvQZP5mfohenwKkxxBb9f-gFvs4ghv7wDDIfr-KBw
*/

export interface IDecodedToken {
	exp: number;
	iat: number;
	id: number;
	name: string;
	email: string;
	is_active: boolean;
	tg_handler: string;
	group_type: string;
	group_name: string;
	user_groups?: Object;
}

export class UserStore {
	private _token: string | null = null;

	private _decodedToken: Partial<IDecodedToken> = {};

	private _tokenIsExpired = false;

	private _atom;

	private _intervalHandler: NodeJS.Timeout | null = null;

	private _timeoutHandler: NodeJS.Timeout | null = null;

	constructor() {
		makeAutoObservable(this);

		this._atom = createAtom(
			"Token",
			() => this._startReceivingToken(),
			() => this._stopReceivingToken()
		);
	}

	get tokenIsExpired() {
		if (this._atom.reportObserved()) return this._tokenIsExpired;

		if (this.decodedToken?.exp === undefined) return true;

		const currentUnix = getCurrentUnix();

		return this.decodedToken?.exp <= currentUnix;
	}

	get isLoggedIn() {
		return !!this.token && !this._tokenIsExpired;
	}

	get login() {
		return this._decodedToken?.name;
	}

	get token() {
		if (this._atom.reportObserved()) return this._token;

		return getRefreshToken();
	}

	get decodedToken(): Partial<IDecodedToken> {
		if (this._atom.reportObserved()) return this._decodedToken;

		return jwtDecode(this.token || "");
	}

	logIn = () => this._receiveTokenFromLocalStorage();

	logOut = () => {
		setAccessToken("");
		setRefreshToken("");
		this._receiveTokenFromLocalStorage();
	};

	private _resetTimeoutHandler() {
		if (!this._timeoutHandler) return;

		clearTimeout(this._timeoutHandler);
		this._timeoutHandler = null;
	}

	private _receiveTokenFromLocalStorage() {
		this._token = getRefreshToken();

		this._decodedToken = this._token ? jwtDecode(this._token) : {};

		const currentUnix = getCurrentUnix();

		if (this._decodedToken?.exp !== undefined)
			this._tokenIsExpired = this._decodedToken?.exp <= currentUnix;

		if (!this._tokenIsExpired && this._decodedToken?.exp !== undefined) {
			const timeoutMs = (this._decodedToken.exp - currentUnix) * 1000;

			this._resetTimeoutHandler();
			this._timeoutHandler = setTimeout(() => {
				this._tokenIsExpired = true;
				this._atom.reportChanged();
			}, timeoutMs);
		}

		this._atom.reportChanged();
	}

	private _startReceivingToken() {
		this._receiveTokenFromLocalStorage();
		this._intervalHandler = setInterval(
			() => this._receiveTokenFromLocalStorage(),
			TOKEN_RECEIVING_INTERVAL
		);
	}

	private _stopReceivingToken() {
		if (this._intervalHandler) clearInterval(this._intervalHandler);
		this._intervalHandler = null;
		this._resetTimeoutHandler();
	}
}
