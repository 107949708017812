import { ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Flex, Modal, Typography } from "antd";
import { observer } from "mobx-react-lite";

import { confirmModalState } from "./model/store";
import { ConfirmModalType } from "./types";

const getModalTitle = (type: ConfirmModalType, title?: React.ReactNode) => {
	const icon =
		type === "confirm" ? (
			<ExclamationCircleOutlined style={{ fontSize: "22px" }} />
		) : (
			<WarningOutlined style={{ fontSize: "22px" }} />
		);

	return (
		<Flex gap={4}>
			{icon}
			{title}
		</Flex>
	);
};

export const UIModalConsent = observer(() => (
	<Modal
		{...confirmModalState.modalProps}
		open={confirmModalState.isOpen}
		title={getModalTitle(confirmModalState.modalType, confirmModalState.modalProps.title)}
	>
		<Typography.Paragraph className="whitespace-break-spaces">
			{confirmModalState.modalProps.content}
		</Typography.Paragraph>
	</Modal>
));
